var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animated fadeIn"},[_c('b-card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('strong',{staticStyle:{"margin-left":"5px"}},[_vm._v("Set Your Quotas")])]),_c('div',{staticClass:"tabbed-form",attrs:{"role":"tablist"}},[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1 form-step-name",attrs:{"header-tag":"header","role":"tab"},on:{"click":function($event){return _vm.toggleStep(0)}}},[_c('img',{attrs:{"src":require("@/assets/gcp-icons/step1.png")}}),_vm._v(" "+_vm._s(_vm.tabs[0])+" ")]),_c('b-collapse',{attrs:{"visible":"","role":"tabpanel"},model:{value:(_vm.tabOpen[0]),callback:function ($$v) {_vm.$set(_vm.tabOpen, 0, $$v)},expression:"tabOpen[0]"}},[_c('b-card-body',[_c('b-form',{staticClass:"step-form"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-card',{staticClass:"quotas-card",attrs:{"no-body":""}},[_c('b-card-header',[_vm._v(" Analytics hits quotas [million] ")]),_c('b-card-body',[_c('b-form-group',{attrs:{"label":"Monthly","label-cols":2,"horizontal":true}},[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"type":"number","title":"Set to 0 for unlimited use","disabled":_vm.isSandbox},on:{"input":value =>
                                                                    (_vm.$v.quotas.hits.monthly.$model = Number(value))},model:{value:(_vm.$v.quotas.hits.monthly.$model),callback:function ($$v) {_vm.$set(_vm.$v.quotas.hits.monthly, "$model", $$v)},expression:"$v.quotas.hits.monthly.$model"}}),_c('Feedback',{attrs:{"state":_vm.validateRef('quotas.hits.monthly', 'quotas'),"invalid":"This field is required","valid":"Quota is valid"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Daily","label-cols":2,"horizontal":true}},[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"type":"number","title":"Set to 0 for unlimited use","disabled":_vm.isSandbox},on:{"input":value =>
                                                                    (_vm.$v.quotas.hits.daily.$model = Number(value))},model:{value:(_vm.$v.quotas.hits.daily.$model),callback:function ($$v) {_vm.$set(_vm.$v.quotas.hits.daily, "$model", $$v)},expression:"$v.quotas.hits.daily.$model"}}),_c('Feedback',{attrs:{"state":_vm.validateRef('quotas.hits.daily', 'quotas'),"invalid":"This field is required","valid":"Quota is valid"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Collected this month","label-cols":2,"horizontal":true}},[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-form-input',{attrs:{"type":"text","readonly":""},model:{value:(_vm.hitsThisMonth),callback:function ($$v) {_vm.hitsThisMonth=$$v},expression:"hitsThisMonth"}})],1)],1)],1)],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-card',{staticClass:"quotas-card",attrs:{"no-body":""}},[_c('b-card-header',[_vm._v(" Processed data quotas [TB] ")]),_c('b-card-body',[_c('b-form-group',{attrs:{"label":"Monthly","label-cols":2,"horizontal":true}},[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"type":"number","title":"Set to 0 for unlimited use","disabled":_vm.isSandbox},on:{"input":value =>
                                                                    (_vm.$v.quotas.data.monthly.$model = Number(value))},model:{value:(_vm.$v.quotas.data.monthly.$model),callback:function ($$v) {_vm.$set(_vm.$v.quotas.data.monthly, "$model", $$v)},expression:"$v.quotas.data.monthly.$model"}}),_c('Feedback',{attrs:{"state":_vm.validateRef('quotas.data.monthly', 'quotas'),"invalid":"This field is required","valid":"Quota is valid"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Daily","label-cols":2,"horizontal":true}},[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"type":"number","title":"Set to 0 for unlimited use","disabled":_vm.isSandbox},on:{"input":value =>
                                                                    (_vm.$v.quotas.data.daily.$model = Number(value))},model:{value:(_vm.$v.quotas.data.daily.$model),callback:function ($$v) {_vm.$set(_vm.$v.quotas.data.daily, "$model", $$v)},expression:"$v.quotas.data.daily.$model"}}),_c('Feedback',{attrs:{"state":_vm.validateRef('quotas.data.daily', 'quotas'),"invalid":"This field is required","valid":"Quota is valid"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Processed this month","label-cols":2,"horizontal":true}},[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-form-input',{attrs:{"type":"text","readonly":""},model:{value:(_vm.bytesThisMonth),callback:function ($$v) {_vm.bytesThisMonth=$$v},expression:"bytesThisMonth"}})],1)],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"disabled":_vm.checkPage.quotas && _vm.$v.quotas.$invalid,"variant":"primary"},on:{"click":_vm.updateQuotas}},[_c('i',{staticClass:"icon-check"}),_vm._v(" Save Quotas ")])],1)],1),_c('b-card',{staticClass:"notifications-card",attrs:{"no-body":""}},[_c('b-card-header',[_vm._v(" Notifications ")]),_c('b-card-body',[_c('b-button',{attrs:{"variant":"warning"},on:{"click":_vm.addNotification}},[_c('i',{staticClass:"icon-plus"}),_vm._v(" Add Notification ")]),_vm._l((_vm.$v.notifications.$each.$iter),function(notification,index){return _c('b-card',{key:index,staticClass:"notifications-card__single",attrs:{"no-body":"","no-header":""}},[_c('b-card-body',[_c('b-form-group',{attrs:{"label":"Subject","label-cols":2,"horizontal":true}},[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('wit-select',{attrs:{"options":_vm.subjectOptions,"label":"label","placeholder":"Select notification subject"},model:{value:(notification.subject.$model),callback:function ($$v) {_vm.$set(notification.subject, "$model", $$v)},expression:"notification.subject.$model"}}),_c('Feedback',{attrs:{"state":_vm.validateRef(
                                                                    `notifications.$each.${index}.subject`,
                                                                    'notifications'
                                                                ),"invalid":"This field is required","valid":"Subject is valid"}})],1),_c('b-col',{staticClass:"notifications-card__single-trash-col"},[_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":() => _vm.removeNotification(index)}},[_c('i',{staticClass:"icon-trash"})])],1)],1)],1),_c('b-form-group',{attrs:{"label":"Periodicity","label-cols":2,"horizontal":true}},[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('wit-select',{attrs:{"options":_vm.periodicityOptions,"label":"label","placeholder":"Select quota's periodicity"},model:{value:(notification.periodicity.$model),callback:function ($$v) {_vm.$set(notification.periodicity, "$model", $$v)},expression:"notification.periodicity.$model"}}),_c('Feedback',{attrs:{"state":_vm.validateRef(
                                                                    `notifications.$each.${index}.periodicity`,
                                                                    'notifications'
                                                                ),"invalid":"This field is required","valid":"Periodicity is valid"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Trigger","label-cols":2,"horizontal":true}},[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('div',{staticClass:"notifications-card__single-slider"},[_c('vue-slider',_vm._b({model:{value:(notification.value.$model),callback:function ($$v) {_vm.$set(notification.value, "$model", $$v)},expression:"notification.value.$model"}},'vue-slider',_vm.sliderOptions,false)),_c('span',[_vm._v("% of quota")])],1),_c('Feedback',{attrs:{"state":_vm.validateRef(
                                                                    `notifications.$each.${index}.value`,
                                                                    'notifications'
                                                                ),"invalid":"This field is required","valid":"Value is valid"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Notified Users","label-cols":2,"horizontal":true}},[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('treeselect',{attrs:{"multiple":true,"options":_vm.users},model:{value:(notification.userIds.$model),callback:function ($$v) {_vm.$set(notification.userIds, "$model", $$v)},expression:"notification.userIds.$model"}}),_c('Feedback',{attrs:{"state":_vm.validateRef(
                                                                    `notifications.$each.${index}.userIds`,
                                                                    'notifications'
                                                                ),"invalid":"This field is required","valid":"Users are valid"}})],1)],1)],1)],1)],1)})],2)],1),_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"disabled":_vm.checkPage.notifications &&
                                                (_vm.$v.notifications.$invalid || !_vm.areNotificationsUnique),"variant":"primary"},on:{"click":_vm.updateNotifications}},[_c('i',{staticClass:"icon-check"}),_vm._v(" Save Notifications ")])],1)],1)],1)],1)],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }