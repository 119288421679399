<template>
    <div class="animated fadeIn">
        <b-card>
            <div slot="header">
                <strong style="margin-left: 5px;">Set Your Quotas</strong>
            </div>

            <div role="tablist" class="tabbed-form">
                <b-card no-body class="mb-1">
                    <b-card-header @click="toggleStep(0)" header-tag="header" class="p-1 form-step-name" role="tab">
                        <img src="@/assets/gcp-icons/step1.png" />
                        {{ tabs[0] }}
                    </b-card-header>
                    <b-collapse v-model="tabOpen[0]" visible role="tabpanel">
                        <b-card-body>
                            <b-form class="step-form">
                                <b-row>
                                    <b-col md="6">
                                        <b-card class="quotas-card" no-body>
                                            <b-card-header>
                                                Analytics hits quotas [million]
                                            </b-card-header>
                                            <b-card-body>
                                                <b-form-group label="Monthly" :label-cols="2" :horizontal="true">
                                                    <b-row>
                                                        <b-col md="8">
                                                            <b-form-input
                                                                v-model="$v.quotas.hits.monthly.$model"
                                                                type="number"
                                                                v-b-tooltip.hover
                                                                title="Set to 0 for unlimited use"
                                                                @input="
                                                                    value =>
                                                                        ($v.quotas.hits.monthly.$model = Number(value))
                                                                "
                                                                :disabled="isSandbox"
                                                            ></b-form-input>
                                                            <Feedback
                                                                :state="validateRef('quotas.hits.monthly', 'quotas')"
                                                                invalid="This field is required"
                                                                valid="Quota is valid"
                                                            ></Feedback>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>

                                                <b-form-group label="Daily" :label-cols="2" :horizontal="true">
                                                    <b-row>
                                                        <b-col md="8">
                                                            <b-form-input
                                                                v-model="$v.quotas.hits.daily.$model"
                                                                type="number"
                                                                v-b-tooltip.hover
                                                                title="Set to 0 for unlimited use"
                                                                @input="
                                                                    value =>
                                                                        ($v.quotas.hits.daily.$model = Number(value))
                                                                "
                                                                :disabled="isSandbox"
                                                            ></b-form-input>
                                                            <Feedback
                                                                :state="validateRef('quotas.hits.daily', 'quotas')"
                                                                invalid="This field is required"
                                                                valid="Quota is valid"
                                                            ></Feedback>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>

                                                <b-form-group
                                                    label="Collected this month"
                                                    :label-cols="2"
                                                    :horizontal="true"
                                                >
                                                    <b-row>
                                                        <b-col md="8">
                                                            <b-form-input
                                                                v-model="hitsThisMonth"
                                                                type="text"
                                                                readonly
                                                            ></b-form-input>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                    <b-col md="6">
                                        <b-card class="quotas-card" no-body>
                                            <b-card-header>
                                                Processed data quotas [TB]
                                            </b-card-header>
                                            <b-card-body>
                                                <b-form-group label="Monthly" :label-cols="2" :horizontal="true">
                                                    <b-row>
                                                        <b-col md="8">
                                                            <b-form-input
                                                                v-model="$v.quotas.data.monthly.$model"
                                                                type="number"
                                                                v-b-tooltip.hover
                                                                title="Set to 0 for unlimited use"
                                                                @input="
                                                                    value =>
                                                                        ($v.quotas.data.monthly.$model = Number(value))
                                                                "
                                                                :disabled="isSandbox"
                                                            ></b-form-input>
                                                            <Feedback
                                                                :state="validateRef('quotas.data.monthly', 'quotas')"
                                                                invalid="This field is required"
                                                                valid="Quota is valid"
                                                            ></Feedback>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>

                                                <b-form-group label="Daily" :label-cols="2" :horizontal="true">
                                                    <b-row>
                                                        <b-col md="8">
                                                            <b-form-input
                                                                v-model="$v.quotas.data.daily.$model"
                                                                type="number"
                                                                v-b-tooltip.hover
                                                                title="Set to 0 for unlimited use"
                                                                @input="
                                                                    value =>
                                                                        ($v.quotas.data.daily.$model = Number(value))
                                                                "
                                                                :disabled="isSandbox"
                                                            ></b-form-input>
                                                            <Feedback
                                                                :state="validateRef('quotas.data.daily', 'quotas')"
                                                                invalid="This field is required"
                                                                valid="Quota is valid"
                                                            ></Feedback>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>

                                                <b-form-group
                                                    label="Processed this month"
                                                    :label-cols="2"
                                                    :horizontal="true"
                                                >
                                                    <b-row>
                                                        <b-col md="8">
                                                            <b-form-input
                                                                v-model="bytesThisMonth"
                                                                type="text"
                                                                readonly
                                                            ></b-form-input>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-button
                                            @click="updateQuotas"
                                            :disabled="checkPage.quotas && $v.quotas.$invalid"
                                            variant="primary"
                                        >
                                            <i class="icon-check"></i> Save Quotas
                                        </b-button>
                                    </b-col>
                                </b-row>

                                <b-card class="notifications-card" no-body>
                                    <b-card-header>
                                        Notifications
                                    </b-card-header>
                                    <b-card-body>
                                        <b-button @click="addNotification" variant="warning">
                                            <i class="icon-plus"></i> Add Notification
                                        </b-button>

                                        <b-card
                                            v-for="(notification, index) in $v.notifications.$each.$iter"
                                            class="notifications-card__single"
                                            no-body
                                            no-header
                                            :key="index"
                                        >
                                            <b-card-body>
                                                <b-form-group label="Subject" :label-cols="2" :horizontal="true">
                                                    <b-row>
                                                        <b-col md="8">
                                                            <wit-select
                                                                v-model="notification.subject.$model"
                                                                :options="subjectOptions"
                                                                label="label"
                                                                placeholder="Select notification subject"
                                                            />
                                                            <Feedback
                                                                :state="
                                                                    validateRef(
                                                                        `notifications.$each.${index}.subject`,
                                                                        'notifications'
                                                                    )
                                                                "
                                                                invalid="This field is required"
                                                                valid="Subject is valid"
                                                            ></Feedback>
                                                        </b-col>
                                                        <b-col class="notifications-card__single-trash-col">
                                                            <b-button
                                                                @click="() => removeNotification(index)"
                                                                variant="danger"
                                                                size="sm"
                                                            >
                                                                <i class="icon-trash"></i>
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>

                                                <b-form-group label="Periodicity" :label-cols="2" :horizontal="true">
                                                    <b-row>
                                                        <b-col md="8">
                                                            <wit-select
                                                                v-model="notification.periodicity.$model"
                                                                :options="periodicityOptions"
                                                                label="label"
                                                                placeholder="Select quota's periodicity"
                                                            />
                                                            <Feedback
                                                                :state="
                                                                    validateRef(
                                                                        `notifications.$each.${index}.periodicity`,
                                                                        'notifications'
                                                                    )
                                                                "
                                                                invalid="This field is required"
                                                                valid="Periodicity is valid"
                                                            ></Feedback>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>

                                                <b-form-group label="Trigger" :label-cols="2" :horizontal="true">
                                                    <b-row>
                                                        <b-col md="8">
                                                            <div class="notifications-card__single-slider">
                                                                <vue-slider
                                                                    v-model="notification.value.$model"
                                                                    v-bind="sliderOptions"
                                                                />
                                                                <span>% of quota</span>
                                                            </div>
                                                            <Feedback
                                                                :state="
                                                                    validateRef(
                                                                        `notifications.$each.${index}.value`,
                                                                        'notifications'
                                                                    )
                                                                "
                                                                invalid="This field is required"
                                                                valid="Value is valid"
                                                            ></Feedback>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>

                                                <b-form-group label="Notified Users" :label-cols="2" :horizontal="true">
                                                    <b-row>
                                                        <b-col md="8">
                                                            <treeselect
                                                                v-model="notification.userIds.$model"
                                                                :multiple="true"
                                                                :options="users"
                                                            />
                                                            <Feedback
                                                                :state="
                                                                    validateRef(
                                                                        `notifications.$each.${index}.userIds`,
                                                                        'notifications'
                                                                    )
                                                                "
                                                                invalid="This field is required"
                                                                valid="Users are valid"
                                                            ></Feedback>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                            </b-card-body>
                                        </b-card>
                                    </b-card-body>
                                </b-card>
                                <b-row>
                                    <b-col>
                                        <b-button
                                            @click="updateNotifications"
                                            :disabled="
                                                checkPage.notifications &&
                                                    ($v.notifications.$invalid || !areNotificationsUnique)
                                            "
                                            variant="primary"
                                        >
                                            <i class="icon-check"></i> Save Notifications
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </b-card>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, requiredIf} from 'vuelidate/lib/validators'
import VueNotifications from 'vue-notifications'
import {mapGetters} from 'vuex'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import {filterDirty} from '@/shared/filterDirty.js'

const cloneDeep = require('lodash.clonedeep')
const d3format = require('d3-time-format')
const isEqual = require('fast-deep-equal')

export default {
    components: {
        Feedback,
        Loading,
        Treeselect,
        VueSlider,
    },
    data() {
        return {
            tabs: ['Quotas Settings'],
            tabIndex: 0,
            checkPage: {
                quotas: false,
                notifications: false,
            },
            quotas: {
                hits: {
                    daily: 0,
                    monthly: 0,
                },
                data: {
                    daily: 0,
                    monthly: 0,
                },
            },
            notifications: [],
            subjectOptions: [
                {
                    label: 'Hits',
                    value: 'HITS',
                },
                {
                    label: 'Processed Data',
                    value: 'DATA',
                },
            ],
            periodicityOptions: [
                {
                    label: 'Daily',
                    value: 'DAILY',
                },
                {
                    label: 'Monthly',
                    value: 'MONTHLY',
                },
            ],
            sliderOptions: {
                min: 1,
                max: 100,
            },
            users: [],
            removeIds: [],
            bytesThisMonth: '',
            hitsThisMonth: '',
        }
    },
    mixins: [validationMixin],
    validations: {
        quotas: {
            hits: {
                daily: {required},
                monthly: {required},
            },
            data: {
                daily: {required},
                monthly: {required},
            },
        },
        notifications: {
            $each: {
                subject: {required},
                periodicity: {required},
                value: {required},
                userIds: {required},
            },
        },
    },
    notifications: {
        updated: {
            type: VueNotifications.types.success,
            title: 'Saved successfully!',
        },
        error: {
            type: VueNotifications.types.error,
            title: 'Could not load properly :(',
        },
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
        tabOpen() {
            return this.tabs.map((tab, index) => (index === this.tabIndex ? true : false))
        },
        areNotificationsUnique() {
            return (
                this.notifications
                    .map(notif => this.notifications.map(el => isEqual(el, notif)).filter(Boolean).length === 1)
                    .filter(Boolean).length === this.notifications.length
            )
        },
        isSandbox() {
            return this.activeProject.type === 'SANDBOX'
        },
    },
    async created() {
        this.$store.commit('loading/PROCESSING', `Loading...`)
        await this.fetchUsers()
        const promises = await Promise.allSettled([
            this.fetchNotifications(),
            this.fetchQuotas(),
            this.fetchHits(),
            this.fetchProcessedData(),
        ])
        if (promises.find(el => el.status === 'rejected')) {
            this.error()
        }
        this.$store.commit('loading/PROCESSED')
    },
    methods: {
        async fetchHits() {
            const {
                data: {data},
            } = await this.axios.post(`${process.env.VUE_APP_NODE_API_HOST}/dashboard/getHits`, {
                witcloudProjectId: this.activeProject.id,
                startDate: d3format.timeFormat('%Y-%m-%d')(
                    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 12, 0, 0)
                ),
                endDate: d3format.timeFormat('%Y-%m-%d')(
                    new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 12, 0, 0)
                ),
            })

            this.hitsThisMonth = (data.reduce((acc, curr) => acc + curr.hits, 0) * Math.pow(10, -6)).toLocaleString(
                undefined,
                {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }
            )
        },

        async fetchProcessedData() {
            const {
                data: {
                    data: [bytes],
                },
            } = await this.axios.post(`${process.env.VUE_APP_NODE_API_HOST}/dashboard/getStats`, {
                witcloudProjectId: this.activeProject.id,
                startDate: d3format.timeFormat('%Y-%m-%d')(
                    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 12, 0, 0)
                ),
                endDate: d3format.timeFormat('%Y-%m-%d')(
                    new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 12, 0, 0)
                ),
            })

            if (bytes) {
                this.bytesThisMonth = this.parseBytesToString(bytes.totalBytesProcessed, 'T')
            }
        },

        parseBytesToString(bytes, unit) {
            if (bytes === 0) return '0 B'
            const prefixes = ['', 'k', 'M', 'G', 'T', 'P']
            const _bytes = prefixes.map((el, index) => ({size: bytes / Math.pow(1024, index), prefix: el}))[
                prefixes.findIndex(u => u === unit)
            ]

            return `${_bytes.size.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })} ${_bytes.prefix}B`
        },

        async fetchUsers() {
            const {
                data: {data},
            } = await this.axios
                .get(`${process.env.VUE_APP_NODE_API_HOST}/permissions`, {
                    params: {
                        limit: 1000,
                        ascending: '0',
                        page: 1,
                        projectId: this.$store.state.project.active.id,
                    },
                })
                .catch(exception => console.error(exception))

            this.users = data.map(user => ({
                id: user.id,
                label: user.member,
            }))
        },

        async fetchQuotas() {
            const {
                data: {data},
            } = await this.axios.get(`${process.env.VUE_APP_NODE_API_HOST}/limits/all`)

            this.quotas = {
                hits: {
                    daily: data.find(el => el.subject === 'HITS' && el.periodicity === 'DAILY').value,
                    monthly: data.find(el => el.subject === 'HITS' && el.periodicity === 'MONTHLY').value,
                },
                data: {
                    daily: data.find(el => el.subject === 'DATA' && el.periodicity === 'DAILY').value,
                    monthly: data.find(el => el.subject === 'DATA' && el.periodicity === 'MONTHLY').value,
                },
            }

            this.currentQuotas = cloneDeep(this.quotas)
        },

        async updateQuotas() {
            this.checkPage.quotas = true
            this.$forceUpdate()

            if (!this.$v.quotas.$invalid) {
                const quotasDirty = filterDirty(this.quotas, this.currentQuotas)

                if (Object.keys(quotasDirty).length > 0) {
                    const {hits, data} = quotasDirty

                    const quotasToUpdate = Object.keys(quotasDirty)
                        .map(subject => {
                            return Object.keys(quotasDirty[subject]).map(periodicity => {
                                if (this.$v.quotas[subject][periodicity].$dirty) {
                                    return {
                                        subject: subject.toUpperCase(),
                                        periodicity: periodicity.toUpperCase(),
                                        value: quotasDirty[subject][periodicity],
                                    }
                                }
                            })
                        })
                        .flat()
                        .filter(Boolean)

                    const requests = quotasToUpdate.map(el =>
                        this.axios.post(`${process.env.VUE_APP_NODE_API_HOST}/limits`, {
                            ...el,
                            projectId: this.activeProject.id,
                        })
                    )

                    this.$store.commit('loading/PROCESSING', `Updating the Quotas...`)

                    try {
                        await Promise.all(requests)
                        this.$v.$reset()
                        this.updated()
                    } catch (e) {
                        console.error(e)
                    }

                    this.$store.commit('loading/PROCESSED')
                }
            }
        },

        async fetchNotifications() {
            const {
                data: {data},
            } = await this.axios.get(`${process.env.VUE_APP_NODE_API_HOST}/limit-notifications/all`)

            this.notifications = data.map(notif => ({
                subject: this.subjectOptions.find(el => el.value === notif.subject),
                periodicity: this.periodicityOptions.find(el => el.value === notif.periodicity),
                value: notif.value,
                userIds: notif.userRefs.map(el => el.id),
                id: notif.id,
            }))
            this.currentNotifications = cloneDeep(this.notifications)
        },

        addNotification() {
            this.notifications.push({
                subject: null,
                periodicity: null,
                value: 1,
                userIds: [],
            })
        },

        async updateNotifications() {
            this.checkPage.notifications = true
            this.$forceUpdate()

            if (!this.$v.notifications.$invalid && this.areNotificationsUnique) {
                const createOrUpdate = this.notifications
                    .map(el => {
                        const notChanged = this.currentNotifications.find(notif => isEqual(notif, el))
                        if (!notChanged) {
                            const data = {
                                subject: el.subject.value,
                                periodicity: el.periodicity.value,
                                value: el.value,
                                userIds: el.userIds,
                            }
                            if (el.id) {
                                return this.axios.patch(
                                    `${process.env.VUE_APP_NODE_API_HOST}/limit-notifications/${el.id}`,
                                    {
                                        ...data,
                                        projectId: this.activeProject.id,
                                    }
                                )
                            } else {
                                return this.axios.post(`${process.env.VUE_APP_NODE_API_HOST}/limit-notifications`, {
                                    ...data,
                                    projectId: this.activeProject.id,
                                })
                            }
                        }
                    })
                    .filter(Boolean)

                const remove = this.removeIds.map(el =>
                    this.axios.delete(`${process.env.VUE_APP_NODE_API_HOST}/limit-notifications/${el}`)
                )

                const requests = [...createOrUpdate, ...remove]

                this.$store.commit('loading/PROCESSING', `Updating the Notifications...`)

                try {
                    await Promise.all(requests)
                    this.$v.notifications.$reset()
                    this.checkPage.notifications = false
                    this.removeIds = []
                    this.updated()
                } catch (e) {
                    console.error(e)
                }

                this.$store.commit('loading/PROCESSED')
            }
        },

        removeNotification(index) {
            if (this.notifications[index].id) {
                this.removeIds.push(this.notifications[index].id)
            }
            this.notifications.splice(index, 1)
        },

        toggleStep(step) {
            this.tabIndex = step
            this.$forceUpdate()
        },

        nextPage(currentPage, disabled) {
            this.checkPage[currentPage] = true
            this.$forceUpdate()
            if (!disabled) this.tabIndex++
        },

        validateRef(ref, part) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            if (this.checkPage[part]) return !validateField.$invalid
            return validateField.$dirty ? !validateField.$invalid : null
        },
    },
}
</script>

<style lang="scss">
.quotas-card {
    .card-header {
        background-color: #f0f3f5;
        font-weight: 600;
    }
    .card-body {
        .form-group {
            margin-bottom: 1.5rem;

            .feedback-container {
                font-size: 0.85rem;
                height: 0.85rem;
                position: absolute;
            }
        }

        .quotas-card__checkbox-col {
            display: flex;
            align-content: center;

            .custom-control-label {
                vertical-align: middle;
                margin-bottom: 0.5rem;
            }
            .custom-control-label::before {
                border: 1px solid #e8e8e8;
            }
        }
    }
}

.notifications-card {
    margin-top: 15px;

    .card-header {
        background-color: #f0f3f5;
        font-weight: 600;
    }
    .notifications-card__single {
        .card-body {
            background-color: #f0f3f5bb;
            border-left: none;
            padding: 1rem;
            border-radius: 8px;

            .notifications-card__single-slider {
                display: flex;
                flex-flow: row nowrap;
                align-content: center;
                justify-content: space-around;

                .vue-slider {
                    display: inline-block;
                    width: 85% !important;
                }
            }

            .notifications-card__single-trash-col {
                display: flex;
                justify-content: flex-end;
                .btn {
                    height: 2.4rem;
                    padding: 0.1rem 0.8rem;
                    i.icon-trash {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
}
</style>
